import gql from 'graphql-tag'

export interface CompletionSuggestionResult {
  getSuggestionsForCompletion: string[]
}

interface SuggestionContext {
  context: string
  boost?: number
}

interface SuggestionFuzziness {
  fuzziness: string
  transpositions?: boolean
  min_length?: number
  prefix_length?: number
}

export interface CompletionSuggestionVariables {
  prefix: string
  size?: number
  tags?: SuggestionContext[]
  fuzzy?: SuggestionFuzziness
}

export const SUGGEST_KEYWORDS_QUERY = gql`
  query getSuggestionsForCompletionQuery(
    $prefix: String!
    $size: Int
    $tags: [SuggestionContext!]
    $fuzzy: SuggestionFuzziness
  ) {
    getSuggestionsForCompletion(prefix: $prefix, size: $size, tags: $tags, fuzzy: $fuzzy)
  }
`
