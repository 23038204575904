import React, { useState, useRef, useEffect } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { EmotionStyles } from '../../../types/types'
import { css } from '@emotion/core'
import { InputProps } from '../Input/Input'
import { PURPLE, GRAY_LIGHT } from '../../../constants/theme.styles'

const containerStyles = css`
  position: relative;
`

const dropdownStyles = css`
  background: #fff;
  overflow: hidden;
  border-left: 1px solid ${PURPLE};
  border-right: 1px solid ${PURPLE};
  border-bottom: 1px solid ${PURPLE};
  margin-top: -5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  z-index: 20;
  left: 0;
  right: 0;
  top: 100%;
  margin-bottom: 100px;
`

const listContainerStyles = css`
  list-style-type: none;
`

const toplineStyles = css`
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px solid ${GRAY_LIGHT};
`

const dropdownItemStyles = css`
  cursor: pointer;
`

// TODO:
// - PASS STYLES
// - APPLY STYLES
// - APPLY ATTACH STYLES
// - ADD EXAMPLES TO STYLEGUIDE

type ClearInputReasons = 'escapeKeyPress'
type InputChangeReasons =
  | 'nativeChangeEvent'
  | 'escapeKeyPress'
  | 'highlightItem'
  | 'selectItem'
  | 'revertToCache'
export type FetchReasons = 'inputChanged' | 'inputFocused' | 'arrowKeyPress' | 'escapeKeyPress'
type SelectSuggestionReasons = 'itemClick' | 'enterKeyPress' | 'tabKeyPress' | 'outsideClick'
type HighlightReasons = 'mouseEnter' | 'arrowUp' | 'arrowDown'
type ClearSuggestionsReasons =
  | 'inputEmpty'
  | 'enterKeyPress'
  | 'tabKeyPress'
  | 'outsideClick'
  | 'itemClick'
  | 'escapeKeyPress'
  | 'failedDisplayDropdownCheck'

interface AutocompleteProps<T> {
  id: string
  // input related props
  onInputChange: (data: { inputValue: string; reason: InputChangeReasons }) => void
  onInputFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onInputBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onInputKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement>,
    data: { isDropdownOpen: boolean; highlightPosition: number }
  ) => void
  inputValue: string
  renderInput: (inputProps: {
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    ref: React.Ref<HTMLInputElement>
  }) => React.ReactNode
  getInputValueFromSuggestItem: (suggestItem: T) => string

  // suggest related props
  suggestions: T[]
  shouldDisplayDropdown?: (data: {
    inputValue: string
    currentSuggestions: T[]
    currentInputValue: string
    isInputFocused: boolean
    lastFetchReason?: FetchReasons
  }) => boolean
  alwaysShowSuggestions?: boolean
  highlightFirstSuggestion?: boolean
  focusInputOnSuggestionClick?: boolean
  renderSuggestItem: (data: { suggestItem: T; isHighlighted: boolean }) => React.ReactNode
  onFetchRequested: (data: { value: string; reason: FetchReasons }) => void
  onSelectSuggestion?: (data: { reason: SelectSuggestionReasons; suggestItem: T }) => void
  onHighlightSuggestion?: (data: { reason: HighlightReasons; suggestItem: T }) => void
  onClearSuggestions: (data: { reason: ClearSuggestionsReasons }) => void

  // can be attached
  attachName?: 'Autocomplete'
  attachInputProps?: InputProps

  // styles
  containerCss?: EmotionStyles
  dropdownCss?: EmotionStyles
  dropdownItemCss?: EmotionStyles
}

function Autocomplete<T>({
  id,
  renderInput,
  inputValue = '',
  getInputValueFromSuggestItem,
  onInputChange,
  onInputFocus,
  onInputBlur,
  onInputKeyDown,
  suggestions = [],
  shouldDisplayDropdown,
  alwaysShowSuggestions = false,
  highlightFirstSuggestion,
  focusInputOnSuggestionClick = true,
  renderSuggestItem,
  onFetchRequested,
  onSelectSuggestion,
  onHighlightSuggestion,
  onClearSuggestions,
  containerCss,
  dropdownCss,
  dropdownItemCss,
  attachInputProps = {}
}: AutocompleteProps<T>) {
  const [isInputFocused, setIsInputFocused] = useState(true)
  const [isDropdownOpen, setIsDropdownOpen] = useState(alwaysShowSuggestions)
  const [highlightPosition, setHighlightPosition] = useState(0)
  const [isHighlightFromHover, setIsHighlightFromHover] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const cachedInputValue = useRef(inputValue)
  const isFocusedByRobot = useRef(false)
  const lastFetchReason = useRef<FetchReasons>()

  // alias constants
  const hasSuggestions = suggestions.length > 0
  const isSomethingHighlighted = highlightPosition > -1
  const isFirstItemHighlighted = highlightPosition === 0
  const isLastItemHighlighted = highlightPosition === suggestions.length - 1

  useEffect(() => {
    const isOpen = alwaysShowSuggestions || (suggestions.length > 0 && isInputFocused)

    if (shouldDisplayDropdown !== undefined && isOpen) {
      const shouldDisplay = shouldDisplayDropdown({
        inputValue,
        currentInputValue: inputValue,
        currentSuggestions: suggestions,
        isInputFocused,
        lastFetchReason: lastFetchReason.current
      })
      setIsDropdownOpen(shouldDisplay)
      if (suggestions.length > 0 && !shouldDisplay) {
        clearSuggestions({ reason: 'failedDisplayDropdownCheck' })
      }
    } else {
      setIsDropdownOpen(isOpen)
    }

    if (isOpen) {
      resetHighlight()
    } else {
      clearHighlight()
    }
  }, [suggestions, isInputFocused, alwaysShowSuggestions])

  // INTERACT WITH DOM
  const refocusInput = () => {
    if (inputRef.current) {
      isFocusedByRobot.current = true
      inputRef.current.focus()
      isFocusedByRobot.current = false
    }
  }

  const getPositionFromDom = (domElement: HTMLElement) => {
    const positionFromDomElement = domElement.getAttribute('data-position')
    return positionFromDomElement ? parseInt(positionFromDomElement) : undefined
  }

  // CONTROL COMPONENT STATE
  const clearInput = (reason: ClearInputReasons) => {
    cachedInputValue.current = ''
    onInputChange({ inputValue: '', reason })
  }
  const getInputValueFromHighlightPosition = (position: number) => {
    const suggestItem = suggestions[position]
    const inputValue = getInputValueFromSuggestItem(suggestItem)
    return typeof inputValue === 'string' ? inputValue : ''
  }

  const setInputValueFromHighlightPosition = (position: number) => {
    const inputValue = getInputValueFromHighlightPosition(position)
    onInputChange?.({ inputValue, reason: 'highlightItem' })
  }

  const setInputValueFromSelect = (position: number) => {
    const inputValue = getInputValueFromHighlightPosition(position)
    onInputChange({ inputValue, reason: 'selectItem' })
    cachedInputValue.current = inputValue
  }

  const clearSuggestions = ({ reason }: { reason: ClearSuggestionsReasons }) => {
    clearHighlight()
    onClearSuggestions({ reason })
  }

  const resetHighlight = () => {
    const highlightPosition = highlightFirstSuggestion ? 0 : -1
    setHighlightPosition(highlightPosition)
    setIsHighlightFromHover(false)
  }

  const clearHighlight = () => {
    setHighlightPosition(-1)
    setIsHighlightFromHover(false)
  }

  const highlightSuggestion = ({
    position,
    reason
  }: {
    position: number
    reason: HighlightReasons
  }) => {
    setHighlightPosition(position)
    const suggestItem = suggestions[position]
    if (onHighlightSuggestion) {
      onHighlightSuggestion({ suggestItem, reason })
    }
  }

  const selectSuggestion = ({
    position,
    reason
  }: {
    position: number
    reason: SelectSuggestionReasons
  }) => {
    setInputValueFromSelect(position)
    if (onSelectSuggestion) {
      const suggestItem = suggestions[position]
      onSelectSuggestion({
        reason,
        suggestItem
      })
    }
  }
  const fetchResults = ({ value, reason }: { value: string; reason: FetchReasons }) => {
    lastFetchReason.current = reason
    onFetchRequested({ value, reason })
  }
  // HANDLE INPUT EVENTS
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    cachedInputValue.current = value
    if (value.length === 0) {
      clearSuggestions({ reason: 'inputEmpty' })
    }
    onInputChange({ inputValue: value, reason: 'nativeChangeEvent' })
    fetchResults({ value, reason: 'inputChanged' })
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsInputFocused(true)
    if (!isFocusedByRobot.current && !alwaysShowSuggestions) {
      fetchResults({ value: inputValue, reason: 'inputFocused' })
    }
    if (onInputFocus) {
      onInputFocus(event)
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onInputBlur) {
      onInputBlur(event)
    }
  }

  // HANDLE KEYBOARD EVENTS
  const handleArrowKeys = (key: string) => {
    setIsHighlightFromHover(false)

    if (!hasSuggestions && !alwaysShowSuggestions) {
      fetchResults({ value: inputValue, reason: 'arrowKeyPress' })
      return
    }

    switch (key) {
      case 'ArrowDown':
        if (isLastItemHighlighted) {
          clearHighlight()
          onInputChange({ inputValue: cachedInputValue.current, reason: 'revertToCache' })
        } else {
          const oneBelowCurrent = highlightPosition + 1
          highlightSuggestion({ position: oneBelowCurrent, reason: 'arrowDown' })
          setInputValueFromHighlightPosition(oneBelowCurrent)
        }
        break
      case 'ArrowUp':
        if (isFirstItemHighlighted) {
          clearHighlight()
          onInputChange({ inputValue: cachedInputValue.current, reason: 'revertToCache' })
        } else if (!isSomethingHighlighted) {
          const lastItemPosition = suggestions.length - 1
          highlightSuggestion({ position: lastItemPosition, reason: 'arrowUp' })
          setInputValueFromHighlightPosition(lastItemPosition)
        } else {
          const oneAboveCurrent = highlightPosition - 1
          highlightSuggestion({ position: oneAboveCurrent, reason: 'arrowUp' })
          setInputValueFromHighlightPosition(oneAboveCurrent)
        }
        break
      default:
        break
    }
  }

  const handleEnterKey = () => {
    if (isSomethingHighlighted) {
      selectSuggestion({ position: highlightPosition, reason: 'enterKeyPress' })
    }
    clearSuggestions({ reason: 'enterKeyPress' })
  }

  const handleTabKey = () => {
    if (isSomethingHighlighted && !isHighlightFromHover) {
      selectSuggestion({ position: highlightPosition, reason: 'tabKeyPress' })
    }
    clearSuggestions({ reason: 'tabKeyPress' })
    setIsInputFocused(false)
  }

  const handleEscapeKey = () => {
    if (alwaysShowSuggestions) {
      clearInput('escapeKeyPress')
      clearHighlight()
      fetchResults({ value: '', reason: 'escapeKeyPress' })
      return
    }

    if (isDropdownOpen) {
      onInputChange({ inputValue: cachedInputValue.current, reason: 'revertToCache' })
    } else {
      clearInput('escapeKeyPress')
    }

    clearSuggestions({ reason: 'escapeKeyPress' })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key
    if (key === 'ArrowUp' || key === 'ArrowDown') {
      event.preventDefault()
      handleArrowKeys(key)
    }
    if (key === 'Enter') {
      handleEnterKey()
      // prevent escape from bubbling and closing modal
      if (isDropdownOpen) {
        event.nativeEvent.stopImmediatePropagation()
      }
    }
    if (key === 'Tab') {
      handleTabKey()
    }
    if (key === 'Escape') {
      handleEscapeKey()
      // prevent escape from bubbling and closing modal
      if (isDropdownOpen || inputValue.length > 0) {
        event.nativeEvent.stopImmediatePropagation()
      }
    }

    if (onInputKeyDown) {
      onInputKeyDown(event, { isDropdownOpen, highlightPosition })
    }
  }

  // HANDLE MOUSE EVENTS
  useOnclickOutside(
    () => {
      if (isSomethingHighlighted) {
        selectSuggestion({ position: highlightPosition, reason: 'outsideClick' })
      }
      clearSuggestions({ reason: 'outsideClick' })
      setIsInputFocused(false)
    },
    { refs: [containerRef] }
  )

  const handleSuggestionClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const position = getPositionFromDom(event.currentTarget)
    if (position !== undefined) {
      clearSuggestions({ reason: 'itemClick' })
      if (focusInputOnSuggestionClick) {
        refocusInput()
      }
      setInputValueFromSelect(position)
      selectSuggestion({ position, reason: 'itemClick' })
    }
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const position = getPositionFromDom(event.currentTarget)
    if (position !== undefined) {
      highlightSuggestion({ position, reason: 'mouseEnter' })
      setIsHighlightFromHover(true)
    }
  }

  const handleMouseLeave = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    clearHighlight()
    setIsHighlightFromHover(false)
  }

  return (
    <div
      role="combobox"
      aria-haspopup="listbox"
      aria-owns={id}
      aria-expanded={isDropdownOpen}
      ref={containerRef}
      css={[containerStyles, containerCss]}
    >
      {renderInput({
        value: inputValue,
        onChange: handleInputChange,
        onKeyDown: handleKeyDown,
        onFocus: handleFocus,
        onBlur: handleBlur,
        ref: inputRef,
        'aria-autocomplete': 'list',
        'aria-controls': id,
        ...attachInputProps
      })}

      {isDropdownOpen ? (
        <div id={id} role="listbox" css={[dropdownStyles, dropdownCss]}>
          <div css={toplineStyles} />
          <ul role="listbox" css={listContainerStyles}>
            {suggestions.map((suggestItem, index) => {
              const isHighlighted = highlightPosition === index

              return (
                <li
                  key={index}
                  role="option"
                  aria-selected={isHighlighted}
                  data-position={index}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleSuggestionClick}
                  css={[dropdownItemStyles, dropdownItemCss]}
                >
                  {renderSuggestItem({ suggestItem, isHighlighted })}
                </li>
              )
            })}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

Autocomplete.displayName = 'Autocomplete'

export { Autocomplete }
