import React, { useRef, useState } from 'react'
import { WithApolloClient, withApollo } from 'react-apollo'
import { Autocomplete } from '../Autocomplete/Autocomplete'
import { InputProps } from '../Input/Input'
import {
  CompletionSuggestionResult,
  CompletionSuggestionVariables,
  SUGGEST_KEYWORDS_QUERY
} from '~/graphql/suggestKeywordsQuery.graphql'
import { useDebouncedCallback } from 'use-debounce/lib'
import { css } from '@emotion/core'
import { BLACK, GRAY_MEDIUM_DARK, GRAY_MEDIUM_LIGHT } from '~/constants/theme.styles'
import { EmotionStyles } from '~/types/types'
import InputBold from '../Input/InputBold'

// i18n support
import { searchKeywordPlaceholder } from '~/lang/langFile'
import { useLang } from '~/context/LangContext'

const dropdownStyles = css`
  border-left: 2px solid ${BLACK};
  border-right: 2px solid ${BLACK};
  border-bottom: 2px solid ${BLACK};
`

const itemStyles = css`
  background: #ffffff;
  color: ${GRAY_MEDIUM_DARK};
  font-size: 15px;
  line-height: 22px;
  padding: 8px 15px;
  margin: 5px 0;
  z-index: 300;
  &:hover {
    background-color: ${GRAY_MEDIUM_LIGHT};
  }
`

const itemHighlightedStyles = css`
  font-weight: 700;
  color: ${BLACK};
`

type Props = {
  keyword: string
  showKeywordMissingError?: boolean
  shouldAutoFocus?: boolean
  maxSuggestions?: number
  inputTestKey?: string
  inputClassName?: string
  placeholder?: string
  onChange?: (value: string, type?: string) => void
  onSubmit?: () => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onUpdateSuggestions?: (suggestions: any) => void

  // can be attached
  attachName?: 'KeywordSuggestionInput'
  attachInputProps?: InputProps
  dropdownCss?: EmotionStyles
  containerCss?: EmotionStyles
  inputCss?: EmotionStyles
  oversize?: boolean
}

type ExtendedProps = WithApolloClient<Props>

const KeywordSuggestionInputPure: React.FC<ExtendedProps> = ({
  keyword,
  showKeywordMissingError = false,
  inputTestKey = 'keyword-field',
  inputClassName = '',
  onChange,
  onSubmit,
  onUpdateSuggestions,
  onBlur,
  onFocus,
  dropdownCss,
  containerCss,
  placeholder,
  inputCss,
  attachInputProps,
  shouldAutoFocus = false,
  maxSuggestions = 10,
  client,
  oversize
}) => {
  const [keywordSuggestions, setKeywordSuggestions] = useState<string[]>([])
  const lastSubmitValue = useRef('')
  const { lang } = useLang()

  const updateSuggestions = (suggestions: any) => {
    setKeywordSuggestions(suggestions)
    onUpdateSuggestions?.(suggestions)
  }

  const fetchSuggestions = async ({ value }: { value: string }) => {
    const { data } = await client.query<CompletionSuggestionResult, CompletionSuggestionVariables>({
      query: SUGGEST_KEYWORDS_QUERY,
      variables: { prefix: value }
    })

    const suggestions = [...data.getSuggestionsForCompletion.slice(0, maxSuggestions)]
    updateSuggestions(suggestions)
  }

  const [debouncedFetch] = useDebouncedCallback(fetchSuggestions, 150, {
    leading: true,
    maxWait: 150
  })

  return (
    <Autocomplete
      id="keyword-suggest-list"
      attachName="Autocomplete"
      dropdownCss={[dropdownStyles, dropdownCss]}
      containerCss={containerCss}
      attachInputProps={attachInputProps}
      onInputFocus={onFocus}
      onInputBlur={onBlur}
      renderInput={({ ref, ...inputProps }) => (
        <InputBold
          fluid
          autoFocus={shouldAutoFocus}
          name="keywordField"
          className={inputClassName}
          data-cy={inputTestKey}
          iconPosition="left"
          icon="searchNew"
          error={showKeywordMissingError}
          autoComplete="off"
          list="autocompleteOff"
          placeholder={placeholder || searchKeywordPlaceholder(lang)[0]}
          aria-label={placeholder || searchKeywordPlaceholder(lang)[0]}
          inputRef={ref}
          oversize={oversize}
          css={[
            css`
              border-width: 2px;
              border-color: #000;

              &:active,
              &:focus {
                border-color: #000;
              }
            `,
            inputCss
          ]}
          {...inputProps}
        />
      )}
      renderSuggestItem={({ suggestItem, isHighlighted }) => {
        return (
          <div css={[itemStyles, isHighlighted ? itemHighlightedStyles : null]}>{suggestItem}</div>
        )
      }}
      inputValue={keyword}
      getInputValueFromSuggestItem={suggestItem => suggestItem}
      suggestions={keywordSuggestions}
      onInputChange={({ inputValue, reason }) => {
        if (typeof inputValue === 'string') {
          onChange?.(inputValue, reason === 'selectItem' ? 'selectedKeywordSuggestion' : reason)
        }
      }}
      shouldDisplayDropdown={({ inputValue, currentSuggestions, lastFetchReason }) => {
        const shouldShow =
          inputValue.length > 1 &&
          !(currentSuggestions.length <= 1 && lastFetchReason === 'inputFocused') &&
          inputValue !== lastSubmitValue.current
        return shouldShow
      }}
      onInputKeyDown={(event, { isDropdownOpen }) => {
        const shouldSubmit = event.keyCode === 13 && !isDropdownOpen
        if (shouldSubmit) {
          lastSubmitValue.current = keyword
          onSubmit?.()
        }
      }}
      onClearSuggestions={() => {
        updateSuggestions([])
      }}
      onFetchRequested={({ value }) => {
        if (value.length <= 1) {
          updateSuggestions([])
          return
        }
        debouncedFetch({ value })
      }}
    />
  )
}

export const KeywordSuggestionInput = withApollo<Props>(KeywordSuggestionInputPure)

KeywordSuggestionInput.displayName = 'KeywordSuggestionInput'
